<template>
  <div>
    <!-- Mapbox component -->
    <mapbox />

    <!-- Top info header -->
    <header-stats />

    <!-- Circular menu -->
    <circular-menu />

  </div>
</template>

<script>
import mapbox from '@/components/mapbox/Mapbox.vue'
import headerStats from '@/components/header-stats/stats.vue'
import circularMenu from '@/components/circular-menu/menu.vue'

export default {
  components: {
    mapbox,
    headerStats,
    circularMenu,
  },
}
</script>

<style>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
